<template>
<div class="lesson-templates">
  <v-layout align-center justify-center row wrap>
    <v-flex xs12 md6 class="pa-3">
      <h3 class="caption grey--text"> <v-icon>store</v-icon> 活动场地承接</h3>
      <span class="subtitle-2">{{lessonInfo.host_name}}-{{lessonInfo.venue_name}}</span>
    </v-flex>
    <v-flex xs12 md6 class="pa-3">
      <h3 class="caption grey--text"> <v-icon>person</v-icon> 主教教师</h3>
      <span class="subtitle-2">{{lessonInfo.tutor}}</span>
    </v-flex>
    <v-flex xs12 class="pa-3">
      <h3 class="caption grey--text"> <v-icon>group</v-icon>
        {{ lessonInfo.tutor_status_code == 2 ? '出席签到(已完成)' : '出席签到(未完成)' }}
        <v-btn
          :color="allSelected ? 'success' : 'warning'"
          @click="selectAll"
          :disabled="allSelected || disabled"
          x-small
          rounded
        >
          {{ allSelected ? '全勤' : '全选' }}
        </v-btn>
        <v-icon
          v-if="!updating && lessonInfo.tutor_status_code == 2" color="success">
          check_circle
        </v-icon>
        <v-icon
          v-if="!lessonInfo.tutor_status_code == 2" color="success">
          check_circle
        </v-icon>
        <v-progress-circular
          v-if="updating"
          indeterminate
          color="red"
          :size="20"
          :width="2"
        ></v-progress-circular>
      </h3>
    </v-flex>
    <v-flex xs12 md12 class="pa-3">
      <v-select
        v-model="studentAttendance"
        :items="students"
        attach
        chips
        :label="disabled? '暂不能签到' : lessonInfo.tutor_status_code == 2 && studentAttendance.length === 0 ? '全部缺席？点此修改' : '点此签到'"
        multiple
        prepend-inner-icon=""
        clearable
        @blur="attendanceUpdate"
        :disabled="disabled"
      ></v-select>
      <div
        v-if="studentsAbsent.length > 0"
        class="text-caption red--text"
      >
        缺席学员:
        <span
          v-for="(studentAbsent, index) in studentsAbsent" v-bind:key="`student-${index}`" class="red--text">
          {{studentAbsent.text}}
        </span>
      </div>
    </v-flex>
  </v-layout>
</div>
</template>

<script>
import LessonService from '@/services/LessonService'
import util from '@/util'

export default {
  props: { // make it possible to pass in parameter <panel title="课程模版">
    lessonId: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  data () {
    return {
      lessonInfo: {},
      lessonEnrollmentInfo: [],
      students: [],
      studentAttendance: [],
      updating: false,
      venueStatusItems: [],
      tutorStatusItems: [],
      fieldRequired: [(value) => !!value || '必填']
    }
  },
  computed: {
    studentsAbsent () {
      let studentsAbsent = []
      this.students.forEach((student) => {
        if (!this.studentAttendance.includes(student.value)) {
          studentsAbsent.push(student)
        }
      })
      return studentsAbsent
    },
    allSelected () {
      if (this.studentAttendance.length === this.students.length) {
        return true
      } else return false
    }
  },
  async mounted () {
    console.log('\nComponent Checkin enabled. \nRetrieve lessonId from path params: ', this.lessonId)
    const resLesson = await LessonService.getLessonSessions({ lessonIds: [this.lessonId] })
    this.lessonInfo = resLesson.data[0]
    console.log('\nRetrieve lesson info: ', this.lessonInfo)
    const resEnrollment = await LessonService.getLessonEnrollments({ lessonIds: [this.lessonId] })
    this.lessonEnrollmentInfo = resEnrollment.data
    console.log('\nRetrieve lessonEnrollmentInfo: ', this.lessonEnrollmentInfo)
    this.lessonEnrollmentInfo.forEach(enrollmentRecord => {
      // construct the select list items
      this.students.push({
        text: enrollmentRecord.student,
        value: enrollmentRecord.student_id
      })
      if (enrollmentRecord.attendance_code === 2) {
        this.studentAttendance.push(enrollmentRecord.student_id)
      }
    })
    console.log('\nFinish constructing students array: ', this.students)
  },
  methods: {
    async selectAll () {
      this.students.forEach((student) => {
        if (!this.studentAttendance.includes(student.value)) {
          this.studentAttendance.push(student.value)
        }
      })
      await this.attendanceUpdate()
    },
    async attendanceUpdate () {
      this.updating = true
      const self = this
      this.lessonEnrollmentInfo.forEach(lessonEnrollment => {
        if (util.inArray(lessonEnrollment.student_id, self.studentAttendance)) {
          lessonEnrollment.attendance_code = 2
        } else {
          lessonEnrollment.attendance_code = 0
        }
        lessonEnrollment.attendance_status = null
      })
      // 签到动作发生，自动将教师与承接方状态设为2:完成
      this.lessonInfo.venue_status_code = 2
      this.lessonInfo.tutor_status_code = 2
      console.log('\nSet venue_status_code to be: ', this.lessonInfo.venue_status_code, '; set tutor_status_code to be: ', this.lessonInfo.tutor_status_code)
      await LessonService.updateLessonEnrollmentAttendanceStatus(this.lessonEnrollmentInfo)
      await LessonService.updateLesson({
        id: this.lessonInfo.id,
        tutor_status_code: this.lessonInfo.tutor_status_code,
        venue_status_code: this.lessonInfo.venue_status_code
      })
      this.updating = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
