<template>
<div class="lesson-enrollment">
  <h1 class="title grey--text">排课-签到</h1>
  <v-container class="my-5">
    <v-row>
      <v-col>
        <v-form
          name="lesson-form"
          ref="lessonSessionEnrollmentForm"
          v-model="isFormVisiblyValid"
          lazy-validation
        >
          <v-layout justify-center row wrap
            v-if="lessonSessions.length > 0"
          >
            <v-flex xs12
              v-for="(lessonSession, index) in lessonSessions"
              :key="`${index}-lessonSession`"
              >
              <v-card class="pa-4">
                <v-switch
                  v-model="lessonSession.control_switch"
                  :label="`排课班级: ${lessonSession.class} `"
                  :disabled="lessonSession.status_code === 2 || lessonSession.class_status_code != 1"
                  @change="switchSession (lessonSession, index)"
                ></v-switch>
                <div v-if="lessonSession.class_status_code != 1"> <v-icon color="warning">warning</v-icon> 班级为活跃状态才可编辑 </div>
                <div v-if="lessonSession.control_switch">
                  <v-chip
                    :color="`${lessonSession.status_code == 2 ? 'green lighten-1': 'orange lighten-2'}`"
                    text-color="white"
                    small
                    v-if="lessonSession.status"
                  >
                    {{ lessonSession.status }}
                  </v-chip>
                  <v-menu
                    :close-on-content-click="false"
                    v-model="lessonSession.menuDatePickerShow"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    :hidden="!lessonSession.control_switch || lessonSession.class_status_code != 1"
                    :disabled="lessonSession.status_code === 2"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        slot="activator"
                        v-model="lessonSession.start_date"
                        :label="dateLabelDisplay(lessonSession.start_date)"
                        prepend-icon="event"
                        readonly
                        :rules ="dateRules"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="lessonSession.start_date"
                      @input="menuDatePickerSaveValue(index)"
                      locale="zh-cn"
                      :first-day-of-week="1"
                    >
                    </v-date-picker>
                  </v-menu>
                  <v-text-field
                    v-model="lessonSession.start_time"
                    v-bind:label="timeLabelDisplay(lessonSession.start_time)"
                    prepend-icon="access_time"
                    readonly
                    @click="lessonSession.menuTimePickerShow = ! lessonSession.menuTimePickerShow"
                  ></v-text-field>
                  <v-time-picker
                    v-if="lessonSession.menuTimePickerShow"
                    v-model="lessonSession.start_time"
                    format="24hr"
                    locale="zh-cn"
                    @change="lessonSession.menuTimePickerShow = ! lessonSession.menuTimePickerShow"
                    transition="scale-transition"
                  ></v-time-picker>
                  <v-btn
                    v-if="!lessonSession.id"
                    color="primary"
                    :loading="lessonSession.beingCreated"
                    :disabled="!lessonSession.start_time || !lessonSession.start_date || lessonSession.status_code === 2 || !isFormVisiblyValid"
                    @click="submitSingleLessonSessionEnrollment(lessonSession, index)">
                    创建
                  </v-btn>
                  <div v-if="lessonSession.id">
                    <CheckIn
                      v-bind:lessonId="lessonSession.id"
                      v-bind:disabled="lessonSession.status_code === LessonStatusLookup.ACCOMPLISHED || Date.parse(lessonSession.time) > new Date().getTime()"
                    ></CheckIn>
                  </div>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="my-4">
      <v-col>
        <v-btn
          block
          color = "error"
          @click="deleteLessonTemplate"
          :disabled="atLeastOneSession"
        >
          删除活动
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import LessonService from '@/services/LessonService'
import ProgramService from '@/services/ProgramService'
import Vue from 'vue'
import moment from 'moment'
import CheckIn from '@/components/CheckIn'
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import { ClassStatusLookup, LessonStatusLookup } from '../enums'

export default {
  components: {
    CheckIn
  },

  data () {
    return {
      // app data
      programId: null,
      // tutorId: null,
      lessonTemplateId: null,
      lessonTemplate: {},
      classes: [],

      // form validation rules
      required: (value) => !!value || '必填',
      fieldRequired: [ this.required ],
      dateRules: [
        (value) => !!value || '必填',
        (value) => differenceInMilliseconds(new Date(), new Date(value)) > 0 || '活动需要完成教授后才能签到'
      ],
      lessonSessions: [],
      lessonSessionInfo: [],
      // lessonEnrollmentInfo: [], // ==todo: push student id and lesson id into this array for generating the enrollment record for all students==
      isFormVisiblyValid: false,
      ClassStatusLookup: ClassStatusLookup,
      LessonStatusLookup: LessonStatusLookup
    }
  },

  computed: {
    atLeastOneSession: function () {
      let hasAtleassonOneSession = false
      this.lessonSessions.forEach((lessonSession) => {
        if (lessonSession.id > 0) {
          hasAtleassonOneSession = true
        }
      })
      return hasAtleassonOneSession
    }
  },

  async created () {
    // set queryingState
    this.$store.dispatch('setQueryingState', true)

    // // get tutorId: 标记session授课教师时用到, 此处改为使用班级主班教师
    // this.tutorId = this.$store.state.account.tutor.id
    // console.log('tutorId: ', this.tutorId)

    // get programId
    this.programId = this.$store.state.route.params.programId
    console.log('programId: ', this.programId)

    // get lessonTemplate
    this.lessonTemplateId = this.$store.state.route.params.lessonTemplateId
    console.log('lessonTemplateId: ', this.lessonTemplateId)
    const resLessonTemplate = await LessonService.getLessonTemplates({ lessonTemplateIds: [this.lessonTemplateId] })
    this.lessonTemplate = resLessonTemplate.data[0]

    // get lessonSessions
    // 原来带了一个 tutorIds, 但是发现template中的tutor_id不一定是lesson的tutor_id,会导致查不到相应session，所以去掉tutorIds
    // const res = await LessonService.getLessonSessions({ lessonTemplateIds: [this.lessonTemplateId], tutorIds: [this.lessonTemplate.tutor_id] })
    const res = await LessonService.getLessonSessions({ lessonTemplateIds: [this.lessonTemplateId] })
    this.lessonSessions = res.data
    console.log('lessonSessions: ', this.lessonSessions)

    // get classes from route params (if query it from database asyncly, inreactive $refs might have problem)
    // this.classes = this.$store.state.route.params.classes
    const resClasses = await ProgramService.getClasses({ instructorTutorIds: [this.lessonTemplate.tutor_id], programIds: [this.programId], statusCodes: [ClassStatusLookup.ACTIVE, ClassStatusLookup.SUSPENDED] })
    this.classes = resClasses.data
    console.log('classes: ', this.classes)

    // initializing lessonSessions
    // 如果属于排课状态，则需要转换课程状态
    for await (const session of this.lessonSessions) {
      if (session.status_code === LessonStatusLookup.SCHEDULED) { // 对于仅排课的课程需要变为已授课状态，并根据班级当前学员配置进行enroll
        await LessonService.lessonEnrollmentInit({ lessonId: session.id })
      }
    }
    const self = this
    this.classes.forEach(function (singleClass, key) {
      // judge lesson session existence
      const lessonSessionIndex = self.lessonSessions.findIndex(function (element) {
        return element.class_id === singleClass.class_id
      })
      if (lessonSessionIndex >= 0) { // lesson session exists for class_id
        // 初始化数据
        Vue.set(self.lessonSessions[lessonSessionIndex], 'start_time', moment(self.lessonSessions[lessonSessionIndex].time).format('HH:mm'))
        Vue.set(self.lessonSessions[lessonSessionIndex], 'start_date', moment(self.lessonSessions[lessonSessionIndex].time).format('YYYY-MM-DD'))
        Vue.set(self.lessonSessions[lessonSessionIndex], 'control_switch', [LessonStatusLookup.SCHEDULED, LessonStatusLookup.INSTRUCTED, LessonStatusLookup.ACCOMPLISHED].includes(self.lessonSessions[lessonSessionIndex].status_code))
        Vue.set(self.lessonSessions[lessonSessionIndex], 'class_status_code', singleClass.status_code)
        Vue.set(self.lessonSessions[lessonSessionIndex], 'menuDatePickerShow', false)
        Vue.set(self.lessonSessions[lessonSessionIndex], 'menuTimePickerShow', false)
        Vue.set(self.lessonSessions[lessonSessionIndex], 'menuTimePickerShow', false)
      } else { // lesson session not exist, create placeholder
        console.log(self.lessonTemplate)
        console.log(self.lessonTemplate)
        self.lessonSessions.push({
          id: null,
          time: null,
          start_time: null,
          start_date: null,
          venue_id: singleClass.venue_id,
          venue_status_code: 1, // mark venue as ready
          tutor_id: self.lessonTemplate.tutor_id, // 此处教师必须与 lessonTemplate 相一致
          tutor_status_code: 1, // mark tutor as ready
          lesson_template_id: self.lessonTemplateId,
          class_id: singleClass.class_id,
          class: singleClass.class,
          class_status_code: singleClass.status_code,
          status_code: 0, // 0:not effective; 1: lesson session arranged; 2: lesson accomplished (only activiate this state when all participants finalized their status and session income has been allocated)
          control_switch: false,
          menuDatePickerShow: false,
          menuTimePickerShow: false
        })
      }
    })
    console.log('lessonSessions(after init): ', this.lessonSessions)

    // set queryingState
    this.$store.dispatch('setQueryingState', false)
  },

  methods: {
    async switchSession (lessonSession, index) {
      console.log('\nSession toggle switch button change state ... ')
      console.log('lessonSession: ', lessonSession, ', index: ', index)
      if (lessonSession.id) {
        console.log('\nlesson session exists, update its status ... ')
        // construct lessonSessionInfo
        // construct lesson time (combine time and date)
        lessonSession.time = lessonSession.start_date + ' ' + lessonSession.start_time
        // determine status_code
        let statusCode = null
        if (lessonSession.status_code === LessonStatusLookup.ACCOMPLISHED) { // if the lesson session is finalized, no change
          statusCode = lessonSession.status_code
        } else if (lessonSession.status_code === LessonStatusLookup.SCHEDULED || lessonSession.status_code === LessonStatusLookup.INSTRUCTED) { // if the lesson session is either disabled or arranged, use swith to toggle the status_code
          if (lessonSession.control_switch) {
            statusCode = 1
          } else {
            statusCode = -1
          }
        }
        if (statusCode === -1) { // lesson session is switched off, delete session
          await LessonService.deleteLesson(lessonSession.id)
          console.log(`lesson(id:${lessonSession.id}) has been deleted!`)
          this.lessonSessions[index].id = null
        } else {
          const lessonSessionInfo = {
            start_time: lessonSession.time,
            venue_id: lessonSession.venue_id,
            venue_status_code: lessonSession.venue_status_code,
            tutor_id: lessonSession.tutor_id,
            tutor_status_code: lessonSession.tutor_status_code,
            lesson_template_id: lessonSession.lesson_template_id,
            class_id: lessonSession.class_id,
            status_code: statusCode
          }
          console.log('Finished! lessonSessionInfo: ', lessonSessionInfo)
          // create lessonSession
          let res
          res = await LessonService.createLessonSession(lessonSessionInfo)
          res.catch((e) => { // error handling
            console.log('error!!')
          })
          const lessonSessionIds = res.data
          console.log('receive lessonSessionIds: ', lessonSessionIds)
          if (lessonSessionIds[0]) {
            this.lessonSessions[index].id = lessonSessionIds[0]
          }
        }
      }
    },
    async submitSingleLessonSessionEnrollment (lessonSession, index) {
      console.log('\nSubmit button is clicked, check form ... ')
      Vue.set(this.lessonSessions[index], 'beingCreated', true)
      // construct lessonSessionInfo
      // construct lesson time (combine time and date)
      lessonSession.time = lessonSession.start_date + ' ' + lessonSession.start_time
      // determine status_code
      let statusCode = null
      if (lessonSession.status_code === LessonStatusLookup.ACCOMPLISHED) { // if the lesson session is finalized, no change
        statusCode = lessonSession.status_code
      } else if (lessonSession.status_code === 0 || lessonSession.status_code === 1) { // if the lesson session is either disabled or arranged, use swith to toggle the status_code
        if (lessonSession.control_switch) {
          statusCode = 1
        } else {
          statusCode = 0
        }
      }
      const lessonSessionInfo = {
        start_time: lessonSession.time,
        venue_id: lessonSession.venue_id,
        venue_status_code: lessonSession.venue_status_code,
        tutor_id: lessonSession.tutor_id,
        tutor_status_code: lessonSession.tutor_status_code,
        lesson_template_id: lessonSession.lesson_template_id,
        class_id: lessonSession.class_id,
        status_code: statusCode
      }
      console.log('Finished! lessonSessionInfo: ', lessonSessionInfo)
      // create lessonSession and create enrollment record for all students in each lesson(class)
      const res = await LessonService.createLessonSession(lessonSessionInfo)
      const lessonSessionId = res.data
      console.log('receive lessonSessionId: ', lessonSessionId)
      if (lessonSessionId) {
        this.lessonSessions[index].id = lessonSessionId
      }
      Vue.set(this.lessonSessions[index], 'beingCreated', false)
    },
    cancel () {
      console.log('user cancel the task.')
      this.$router.push({ name: 'lesson-templates' })
    },
    async menuDatePickerSaveValue (lessonSessionIndex) {
      console.log('menuDatePicker event triggerred!')
      this.lessonSessions[lessonSessionIndex].menuDatePickerShow = false
      if (this.lessonSessions[lessonSessionIndex].id) { // lesson session exists, update lesson
        console.log('lesson session exists, update ... ')
        await this.updateLessonStartTime(lessonSessionIndex)
        console.log('success！')
      }
    },
    async updateLessonStartTime (lessonSessionIndex) {
      this.lessonSessions[lessonSessionIndex].time = this.lessonSessions[lessonSessionIndex].start_date + ' ' + this.lessonSessions[lessonSessionIndex].start_time
      await LessonService.updateLesson({
        'id': this.lessonSessions[lessonSessionIndex].id,
        'start_time': this.lessonSessions[lessonSessionIndex].time
      })
    },
    async deleteLessonTemplate () {
      console.log(`deleteLessonTemplate starts ... `)
      await LessonService.deleteLessonTemplate(this.lessonTemplateId)
      console.log('successful! returning to lesson templates list ... ')
      this.$router.push({ name: 'lesson-templates' })
    },
    dateLabelDisplay (date) {
      if (date) {
        return '活动日期: ' + moment(date).format('M/D (dddd)')
      } else {
        return '活动日期'
      }
    },
    timeLabelDisplay (time) {
      if (time) {
        return '活动时间: ' + moment(time, [moment.ISO_8601, 'HH:mm']).format('A hh时mm分')
      } else {
        return '活动时间'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
